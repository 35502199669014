<!-- @format -->

<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">Edit Policy</h3>
                  </div>
                </div>

                <div class="invoice-number-date mt-md-0">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">Agent ID</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_id" disabled />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">Agent name</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_name" disabled />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">Agent Level</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_level" disabled />
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Policy Number:</h6>
                <validation-provider #default="{ errors }" name="Policy Number">
                  <b-form-input v-model="formData.number" placeholder="Policy Number" :state="errors.length > 0 ? false : null" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Select a product:</h6>
                <validation-provider #default="{ errors }" name="Policy Number">
                  <!--                  <b-form-input v-model="formData.product_id" :state="errors.length > 0 ? false : null" placeholder="Policy Number" />-->
                  <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
                  <b-row>
                    <b-col>
                      <vSelect placeholder="Select company" v-model="c" :options="comptry" :reduce="item => item.id" label="title" />
                    </b-col>
                    <b-col>
                      <vSelect placeholder="Select Product Type" v-model="d" :options="cate" :reduce="item => item.id" label="title" />
                    </b-col>
                    <b-col>
                      <vSelect placeholder="Select a product" v-model="formData.product_id" label="title" :reduce="item => item.id" :options="productList" :disabled="yulandis" />
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Insured:</h6>
                <validation-provider #default="{ errors }" name="Insured">
                  <b-form-input v-model="formData.protect_user" :state="errors.length > 0 ? false : null" placeholder="Insured" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">FaceAmount:</h6>
                <validation-provider #default="{ errors }" name="FaceAmount">
                  <b-form-input v-model="formData.money" :state="errors.length > 0 ? false : null" placeholder="FaceAmount" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Signed address:</h6>
                <validation-provider #default="{ errors }" name="Signed address">
                  <b-form-input v-model="formData.sign_city" :state="errors.length > 0 ? false : null" placeholder="Signed address" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!--            <b-row class="invoice-spacing mb-0 ml-2">-->
            <!--              <b-col cols="12" xl="6" class="mb-lg-1">-->
            <!--                <h6 class="mb-2">Paid Percent:</h6>-->
            <!--                <validation-provider #default="{ errors }" name="Paid Percent">-->
            <!--                  <b-form-input v-model="formData.rate" :state="errors.length > 0 ? false : null" placeholder="Paid Percent" :disabled="yulandis" />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!--            <b-row class="invoice-spacing mb-0 ml-2">-->
            <!--              <b-col cols="12" xl="6" class="mb-lg-1">-->
            <!--                <h6 class="mb-2">被除率:</h6>-->
            <!--                <validation-provider #default="{ errors }" name="被除率">-->
            <!--                  <b-form-input v-model="formData.except_rate" :state="errors.length > 0 ? false : null" placeholder="被除率" :disabled="yulandis" />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--            </b-row>-->

            <!--            <b-row class="invoice-spacing mb-0 ml-2">-->
            <!--              <b-col cols="12" xl="6" class="mb-lg-1">-->
            <!--                <h6 class="mb-2">付费百分比:</h6>-->
            <!--                <validation-provider #default="{ errors }" name="付费百分比">-->
            <!--                  <b-form-input v-model="formData.fee_rate" :state="errors.length > 0 ? false : null" placeholder="付费百分比" :disabled="yulandis" />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--            </b-row>-->

            <b-row class="invoice-spacing mb-0 ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Date of creation:</h6>
                <validation-provider #default="{ errors }" name="Date of creation">
                  <flat-pickr v-model="formData.deal_time" class="form-control" :config="timePickerConfig" placeholder="Select creation date" :disabled="yulandis" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!--            <b-row class="invoice-spacing mb-0 ml-2">-->
            <!--              <b-col cols="12" xl="6" class="mb-lg-1">-->
            <!--                <h6 class="mb-2">Policy TP:</h6>-->
            <!--                <validation-provider #default="{ errors }" name="Policy TP">-->
            <!--                  <b-form-input v-model="formData.order_tp" :state="errors.length > 0 ? false : null" placeholder="Policy TP" :disabled="yulandis" />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </validation-provider>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <b-card-body class="invoice-padding form-item-section">
              <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                <b-row v-for="(item, index) in formData.share_rate" :key="index" ref="row" class="pb-2">
                  <b-col cols="12">
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="3"> Agent ID </b-col>
                        <b-col cols="12" lg="3"> Agent name </b-col>
                        <b-col cols="12" lg="3"> Agent Level </b-col>
                        <b-col cols="12" lg="3"> Split Percent </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col cols="12" lg="3">
                          <b-form-input
                            v-model="formData.share_rate[index].search_id"
                            type="number"
                            class="mb-2"
                            @blur="searchUserId(index)"
                            @keyup.enter="searchUserId(index)"
                            placeholder="Agent ID"
                            :disabled="yulandis"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <b-form-input v-model="formData.share_rate[index].create_name" class="mb-2" disabled placeholder="Agent ID" />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <b-form-input v-model="formData.share_rate[index].create_level" class="mb-2" disabled placeholder="Agent ID" />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <b-form-input v-model="formData.share_rate[index].share_rate" class="mb-2" placeholder="Agent Split Percent" :disabled="!formData.share_rate[index].create_name || yulandis" />
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                      </div>
                      {{ error.text }}
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm"> Add Split Agent </b-button>
            </b-card-body>

            <b-row class="invoice-spacing ml-2">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">Policy Remarks:</h6>
                <b-form-textarea v-model="formData.desc" placeholder="Policy Remarks" :disabled="yulandis" />
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">Confirm</b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="yulandis = !yulandis"> {{ yulandis ? 'Cancel Preview' : 'Preview' }} </b-button>
          <!--          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> Download </b-button>-->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, getCurrentInstance, computed, nextTick, onMounted, reactive, watch } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'

import { BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import orderModule from '@/views/GeneralManagement/OrderManager/OrderList/OrderManagerList'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import zh from 'flatpickr/dist/l10n'
import { required } from 'vee-validate/dist/rules'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      if (this.formData.share_rate.length < 6) {
        this.$refs.form.style.overflow = 'hidden'
        this.formData.share_rate.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      } else {
        this.$bvToast.toast('Add up to 6 Split Percent', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    removeItem(index) {
      this.formData.share_rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.trSetHeight(this.$refs.form.scrollHeight)
          }, 1000)
        })
      }, 200)
    },
    forceUpdated() {
      this.$forceUpdate()
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()

    const formData = computed(() => store.state['user-orders'].orderDetail)

    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    const vipList = reactive([])
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) store.registerModule('product-list', ListManagerModule)
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }
    // const cityList = reactive([])

    const itemFormBlankItem = {
      user_id: undefined,
      share_rate: 0,
    }

    const error = reactive({
      text: '',
      component: '',
    })

    onMounted(() => {
      nextTick(() => {
        fetchProdList()
        axiosIns.get('/user/list').then(response => {
          if (response.code === 0) {
            vipList.push(...response.data.list)
          }
        })
        axiosIns.get('/order/create-user').then(response => {
          formData.value.create_name = response.data.real_name
          formData.value.create_id = response.data.id
          formData.value.create_level = response.data.level.name
        })
        if (formData.value) {
          formData.value.share_rate.forEach(item => {
            item.search_id = item.user_id
            item.create_name = item?.user_info.real_name || item?.create_name
            item.create_level = item?.user_info.level.name
            item.share_rate = item?.share_rate
          })
        }
      })
    })

    const isLoading = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const submitAddProduct = () => {
      isLoading.value = true
      // proxy.$refs.registerForm.validate().then(isOk => {
      //   if (isOk) {
      //     if (error.text || error.component) {
      //       toast.error(error.text)
      //       isLoading.value = false
      //       return
      //     }
      const editData = {
        product_id: '',
        // status: '1',
        number: '',
        deal_time: '',
        // order_tp: '',
        user_id: '',
        share_rate: [],
        protect_user: '', // 被保人
        sign_city: '', // Signed address
        money: '', // FaceAmount
        create_name: '',
        create_id: '',
        create_level: '',
        id: '',
        rate: '',
        except_rate: '',
        // fee_rate: '',
        desc: ''
      }
      Object.keys(editData).forEach(key => {
        if (formData.value[key]) {
          editData[key] = formData.value[key]
        }
      })

      store
        .dispatch('user-order/orderUpdate', {
          ...editData,
        })
        .then(response => {
          if (response.code === 0) {
            toast.success(response.msg || 'Added successfully')
            setTimeout(() => {
              isLoading.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            isLoading.value = false
            toast.error(response.msg)
          }
        })
        .catch(() => {
          toast.error('Failed to add')
          isLoading.value = false
        })
      //   } else {
      //     toast.error('Please complete the form')
      //     isLoading.value = false
      //   }
      // })
    }

    const searchId = ref(null)
    const searchUserId = val => {
      if (!formData.value.share_rate[val].search_id) {
        formData.value.share_rate[val] = {}
        proxy.forceUpdated()
        return
      }
      axiosIns.get(`/user/getUserInfoById?id=${formData.value.share_rate[val].search_id}`).then(res => {
        if (res.code == 0) {
          // 搜索原有数据,查找是否存在该树
          const findIndex = formData.value.share_rate.findIndex(item => item.user_id == res.data.id)
          if (findIndex < 0) {
            nextTick(() => {
              formData.value.share_rate[val].user_id = res.data.id
              formData.value.share_rate[val].share_rate = res.data.share_rate
              formData.value.share_rate[val].create_name = res.data.real_name
              formData.value.share_rate[val].create_level = res.data.level.name
            })
            proxy.forceUpdated()
          } else if (findIndex == 0) {
            toast('Agent Already exists!')
            formData.value.share_rate[val] = {}
            proxy.forceUpdated()
          }
        } else {
          toast(res.msg, {
            type: 'error',
            duration: 2000,
          })
          formData.value.share_rate[val] = {}
          proxy.forceUpdated()
        }
      })
    }
    const comptry = reactive([])
    const cate = reactive([])
    const c = ref('')
    const d = ref('')

    onMounted(() => {
      axiosIns.get('/pro-brand/getBrandList').then(res => {
        if (res.code === 0) {
          comptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getCateList').then(res => {
        if (res.code === 0) {
          cate.push(...res.data)
        }
      })
    })
    watch(
      [c, d, formData.value.share_rate],
      () => {
        if (c.value && d.value) {
          axiosIns.get(`/product/getProduct?cate_id=${c.value}&cate_id=${d.value}`).then(res => {
            if (res.code === 0) {
              productList.splice(0, productList.length, ...res.data)
              if (res.data.length == 0) {
                formData.value.product_id = ''
              }
              proxy.forceUpdated()
            }
          })
        }
      },
      { immediate: true, deep: true },
    )

    watch(
      () => formData.value.share_rate,
      newVal => {
        let total = 0
        newVal.forEach(item => {
          total += Number(item.share_rate)
        })
        if (total > 100) {
          error.text = 'Split Percent cannot be greater than 100%'
          error.component = 'b-link'
        }
        if (total < 100) {
          error.text = 'Split Percent cannot be less than 100%'
          error.component = 'b-link'
        }
        if (total === 100) {
          error.text = ''
          error.component = ''
        }
      },
      { immediate: true, deep: true },
    )

    const yulandis = ref(false)
    const downLoad = () => {
      document.body.style.cursor = 'wait'
      // console.log(document.querySelector('.woc').offsetWidth)
      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        html2canvas(document.querySelector('.woc '), {
          height: document.querySelector('.woc').scrollHeight + 300,
          width: document.querySelector('.woc').offsetWidth + 100,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 1,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 320, 320, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }

    return {
      yulandis,
      downLoad,
      productList,
      c,
      d,
      comptry,
      cate,
      itemFormBlankItem,
      hasNextPage,
      hasPrevPage,
      formData,
      submitAddProduct,
      isLoading,
      timePickerConfig,
      searchId,
      searchUserId,
      required,
      error,
    }
  },
  beforeRouteEnter(to, from, next) {
    // 进入页面前检查detailInfo是否存在id
    next(vm => {
      if (!vm.formData?.id) {
        vm.$bvToast.toast('Wrong parameter Please select again', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          appendToast: false,
          autoHideDelay: 2000,
        })
        setTimeout(() => {
          vm.$router.back()
        }, 2000)
      } else {
        next()
      }
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
